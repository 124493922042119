export const AboutUsPageSections = () => import('../../components/AboutUs/PageSections.vue' /* webpackChunkName: "components/about-us-page-sections" */).then(c => wrapFunctional(c.default || c))
export const AboutUsTeam = () => import('../../components/AboutUs/Team.vue' /* webpackChunkName: "components/about-us-team" */).then(c => wrapFunctional(c.default || c))
export const AboutUsTeamMemberCard = () => import('../../components/AboutUs/TeamMemberCard.vue' /* webpackChunkName: "components/about-us-team-member-card" */).then(c => wrapFunctional(c.default || c))
export const ContactInformationBlock = () => import('../../components/Contact/InformationBlock.vue' /* webpackChunkName: "components/contact-information-block" */).then(c => wrapFunctional(c.default || c))
export const HomePageOurWork = () => import('../../components/HomePage/OurWork.vue' /* webpackChunkName: "components/home-page-our-work" */).then(c => wrapFunctional(c.default || c))
export const HomePageOurWorkMobile = () => import('../../components/HomePage/OurWorkMobile.vue' /* webpackChunkName: "components/home-page-our-work-mobile" */).then(c => wrapFunctional(c.default || c))
export const HomePageOurWorkSection = () => import('../../components/HomePage/OurWorkSection.vue' /* webpackChunkName: "components/home-page-our-work-section" */).then(c => wrapFunctional(c.default || c))
export const HomePageServicesBlock = () => import('../../components/HomePage/ServicesBlock.vue' /* webpackChunkName: "components/home-page-services-block" */).then(c => wrapFunctional(c.default || c))
export const HomePageServicesSection = () => import('../../components/HomePage/ServicesSection.vue' /* webpackChunkName: "components/home-page-services-section" */).then(c => wrapFunctional(c.default || c))
export const HomePageTechnologySection = () => import('../../components/HomePage/TechnologySection.vue' /* webpackChunkName: "components/home-page-technology-section" */).then(c => wrapFunctional(c.default || c))
export const HomePageTrustedBySection = () => import('../../components/HomePage/TrustedBySection.vue' /* webpackChunkName: "components/home-page-trusted-by-section" */).then(c => wrapFunctional(c.default || c))
export const MainComponentContactForm = () => import('../../components/MainComponent/ContactForm.vue' /* webpackChunkName: "components/main-component-contact-form" */).then(c => wrapFunctional(c.default || c))
export const MainComponentContactSection = () => import('../../components/MainComponent/ContactSection.vue' /* webpackChunkName: "components/main-component-contact-section" */).then(c => wrapFunctional(c.default || c))
export const MainComponentCustomLottiePlayer = () => import('../../components/MainComponent/CustomLottiePlayer.vue' /* webpackChunkName: "components/main-component-custom-lottie-player" */).then(c => wrapFunctional(c.default || c))
export const MainComponentFooter = () => import('../../components/MainComponent/Footer.vue' /* webpackChunkName: "components/main-component-footer" */).then(c => wrapFunctional(c.default || c))
export const MainComponentHamburgerBar = () => import('../../components/MainComponent/HamburgerBar.vue' /* webpackChunkName: "components/main-component-hamburger-bar" */).then(c => wrapFunctional(c.default || c))
export const MainComponentHeaderSection = () => import('../../components/MainComponent/HeaderSection.vue' /* webpackChunkName: "components/main-component-header-section" */).then(c => wrapFunctional(c.default || c))
export const MainComponentHeadingSection = () => import('../../components/MainComponent/HeadingSection.vue' /* webpackChunkName: "components/main-component-heading-section" */).then(c => wrapFunctional(c.default || c))
export const MainComponentLoadingScreen = () => import('../../components/MainComponent/LoadingScreen.vue' /* webpackChunkName: "components/main-component-loading-screen" */).then(c => wrapFunctional(c.default || c))
export const MainComponentMobileMenuSection = () => import('../../components/MainComponent/MobileMenuSection.vue' /* webpackChunkName: "components/main-component-mobile-menu-section" */).then(c => wrapFunctional(c.default || c))
export const MainComponentOtherServices = () => import('../../components/MainComponent/OtherServices.vue' /* webpackChunkName: "components/main-component-other-services" */).then(c => wrapFunctional(c.default || c))
export const MainComponentPageChangeAnimation = () => import('../../components/MainComponent/PageChangeAnimation.vue' /* webpackChunkName: "components/main-component-page-change-animation" */).then(c => wrapFunctional(c.default || c))
export const MainComponentPrivacyPolicy = () => import('../../components/MainComponent/PrivacyPolicy.vue' /* webpackChunkName: "components/main-component-privacy-policy" */).then(c => wrapFunctional(c.default || c))
export const MainComponentProject = () => import('../../components/MainComponent/Project.vue' /* webpackChunkName: "components/main-component-project" */).then(c => wrapFunctional(c.default || c))
export const MainComponentProjectCarousel = () => import('../../components/MainComponent/ProjectCarousel.vue' /* webpackChunkName: "components/main-component-project-carousel" */).then(c => wrapFunctional(c.default || c))
export const MainComponentProjectMobile = () => import('../../components/MainComponent/ProjectMobile.vue' /* webpackChunkName: "components/main-component-project-mobile" */).then(c => wrapFunctional(c.default || c))
export const MainComponentProjectsSection = () => import('../../components/MainComponent/ProjectsSection.vue' /* webpackChunkName: "components/main-component-projects-section" */).then(c => wrapFunctional(c.default || c))
export const MainComponentScrollToTopButton = () => import('../../components/MainComponent/ScrollToTopButton.vue' /* webpackChunkName: "components/main-component-scroll-to-top-button" */).then(c => wrapFunctional(c.default || c))
export const MainComponentSocialNetworksTab = () => import('../../components/MainComponent/SocialNetworksTab.vue' /* webpackChunkName: "components/main-component-social-networks-tab" */).then(c => wrapFunctional(c.default || c))
export const MainComponentStickyNavigation = () => import('../../components/MainComponent/StickyNavigation.vue' /* webpackChunkName: "components/main-component-sticky-navigation" */).then(c => wrapFunctional(c.default || c))
export const MainComponentTestimonials = () => import('../../components/MainComponent/Testimonials.vue' /* webpackChunkName: "components/main-component-testimonials" */).then(c => wrapFunctional(c.default || c))
export const MainComponentTopBannerSection = () => import('../../components/MainComponent/TopBannerSection.vue' /* webpackChunkName: "components/main-component-top-banner-section" */).then(c => wrapFunctional(c.default || c))
export const MainComponentWorkInvitationBlock = () => import('../../components/MainComponent/WorkInvitationBlock.vue' /* webpackChunkName: "components/main-component-work-invitation-block" */).then(c => wrapFunctional(c.default || c))
export const ProjectCompletedProjects = () => import('../../components/Project/CompletedProjects.vue' /* webpackChunkName: "components/project-completed-projects" */).then(c => wrapFunctional(c.default || c))
export const ProjectCompletedSection = () => import('../../components/Project/CompletedSection.vue' /* webpackChunkName: "components/project-completed-section" */).then(c => wrapFunctional(c.default || c))
export const ProjectIntro = () => import('../../components/Project/ProjectIntro.vue' /* webpackChunkName: "components/project-intro" */).then(c => wrapFunctional(c.default || c))
export const ServiceDesignTechnologies = () => import('../../components/Service/DesignTechnologies.vue' /* webpackChunkName: "components/service-design-technologies" */).then(c => wrapFunctional(c.default || c))
export const ServiceServicesContent = () => import('../../components/Service/ServicesContent.vue' /* webpackChunkName: "components/service-services-content" */).then(c => wrapFunctional(c.default || c))
export const ServiceServicesInformation = () => import('../../components/Service/ServicesInformation.vue' /* webpackChunkName: "components/service-services-information" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
